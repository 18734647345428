import { shortFormatDate, formatTransactionStatus, formatValueCurrency } from '@/helpers'

export default {
  name: 'TTransactionsTable',
  data () {
    return {
      thead: [
        { title: 'Status', classes: 'status', key: 'status', order: null },
        { title: 'Data de Vencimento', classes: '', key: 'updatedAt', order: null },
        { title: 'Doador', classes: '', key: 'customerName', order: null },
        { title: 'Método', classes: '', key: 'paymentMethod', order: null },
        { title: '$ Valor', classes: 'right aligned collapsing', key: 'amount', order: null }
      ]
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    payments: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    $('.tui.ui.dropdown').dropdown()
  },
  methods: {
    itemId: item => item.id,

    paymentMethod: item => item.paymentMethod,

    formatedData (data) {
      return {
        status: formatTransactionStatus(data.status, data.statusLabel),
        date: shortFormatDate(data.dueDate),
        donor: this.donorName(data.customer),
        method: data.paymentMethodLabel,
        value: formatValueCurrency(data.amount),
      }
    },

    donorName(customer) {
      if (!customer.firstName && !customer.lastName) {
        return customer.companyName
      } else {
        return `${customer.firstName} ${customer.lastName}`
      }
    },

    orderTable (field, order) {
      const header = this.thead.find( head => head.key === field )
      if (!order || order === 'asc') {
        order = 'desc'
      } else {
        order = 'asc'
      }
      header.order = order

      this.thead.forEach(head => {
        if (head.key !== field) head.order = null
      })

      this.$emit('orderBy', { field, order })
    }
  }
}
